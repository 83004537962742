import React from 'react';
import PropTypes from 'prop-types';
// import './App.css';
import '../../node_modules/react-vis/dist/style.css';
import {
  FlexibleWidthXYPlot,
  VerticalBarSeries,
  HorizontalGridLines,
  XAxis,
  YAxis
} from 'react-vis';

import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

import { withTheme } from '@material-ui/core/styles';

const colors = [
  '#001F3F',
  '#0074D9',
  '#7FDBFF',
  '#39CCCC',
  '#3D9970',
  '#2ECC40',
  '#01FF70',
  '#FFDC00',
  '#FF851B',
  '#FF4136',
  '#85144B',
  '#F012BE',
  '#B10DC9'
];

let getRndInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

const randInt = getRndInteger(2, 12);

let ReactVisGrapher = ({ classes, currentUser, data, theme }) => {
  const atLeastSmall = useMediaQuery(theme.breakpoints.up('sm'));

  data = data.map((obj, index) => {
    // console.log(obj);
    return {
      ...obj,
      color: colors[(index + randInt) % 13],
      x: !atLeastSmall
        ? obj.x.length > 5
          ? JSON.stringify(obj.x)
              .substr(0, 5)
              .concat('...')
          : JSON.stringify(obj.x)
        : obj.x.length > 10
        ? JSON.stringify(obj.x)
            .substr(0, 10)
            .concat('...')
        : JSON.stringify(obj.x)
    };
  });

  let max = Math.min(10, Math.max(...data.map(obj => obj.y)));
  let state = { data: data, max: max };

  return (
    <div>
      <FlexibleWidthXYPlot
        height={300}
        colorType="literal"
        xType={'ordinal'}
        animation={true}
        margin={!atLeastSmall ? { bottom: 100 } : {}}
      >
        <HorizontalGridLines tickTotal={state.max} />
        <VerticalBarSeries data={state.data} opacity={1} />
        <XAxis
          tickSize={0}
          tickLabelAngle={atLeastSmall ? 0 : -90}
          style={{
            text: theme.typography.body1
          }}
        />
        <YAxis
          tickSizeOuter={0}
          tickTotal={state.max}
          style={{
            text: theme.typography.body1
          }}
        />
      </FlexibleWidthXYPlot>
    </div>
  );
};

ReactVisGrapher.propTypes = {
  data: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired
};

export default withTheme()(ReactVisGrapher);
