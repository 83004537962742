import React, { useState } from 'react';
import PropTypes from 'prop-types';
import jwt from 'jsonwebtoken';

// Forms
import LoginForm from '../mui-forms/login-form';

import { tokenKey, currentUserKey } from '../../credentials';

import { withStyles } from '@material-ui/core/styles';
import { Typography, Link } from '@material-ui/core';
import { Link as RRLink } from 'react-router-dom';
import { Redirect } from 'react-router';
import { makeAPICall } from '../../api';
import my_styles from '../../my-styles';
const styles = my_styles.MUIApp_styles;

// ---------------------------------------------------------------------------

let LoginUser = ({
  classes,
  currentUser,
  updateUser,
  location,
  enqueueSnackbar
}) => {
  let [message, setMessage] = useState('');
  let [inProgress, setInProgress] = useState(false);

  if (currentUser.authenticated) {
    const { from } = location.state || {
      from: { pathname: '/' }
    };
    console.log(`Redirecting to ${from.pathname}`); //${coming_from.pathname}`);
    return <Redirect to={from} />;
  }

  let loginUser = async values => {
    setMessage('');
    setInProgress(true);
    try {
      let res = await makeAPICall('POST', '/api/login', values);
      let body = await res.json();
      setInProgress(false);
      setMessage(body.message);
      if (res.status === 200) {
        localStorage.setItem(tokenKey, body.token);
        localStorage.setItem(currentUserKey, JSON.stringify(body.user));
        const decoded = jwt.decode(localStorage.getItem(tokenKey));
        updateUser({
          ...body.user,
          authenticated: true,
          admin: decoded.data.admin
        });
        enqueueSnackbar(`Welcome back ${body.user.username}!`, {
          variant: 'success',
          autoHideDuration: 3000
        });
      }
    } catch (err) {
      setInProgress(false);
      setMessage('Unable to connect to server.');
    }
  };

  return (
    <div className={classes.root}>
      <Typography align="center" variant="h5" gutterBottom>
        Please Log In
      </Typography>
      <LoginForm
        onSubmit={loginUser}
        message={message}
        inProgress={inProgress}
      />
      <Typography align="center" variant="body1" gutterBottom>
        Don't have an account?{' '}
        <Link component={RRLink} to={`/register`}>
          Sign Up!
        </Link>
      </Typography>
    </div>
  );
};

LoginUser.propTypes = {
  location: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired
};

export default withStyles(styles)(LoginUser);
