import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RRLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularLoadButton from '../../components/MUICircularLoadButton';
import my_styles from '../../my-styles';
const styles = my_styles.MUIApp_styles;

let RegisterForm = ({
  classes,
  onSubmit,
  inProgress,
  isSuccess,
  message,
  username,
  firstname,
  lastname,
  email,
  update_password
}) => {
  let [values, setValues] = useState({
    update_password: update_password || false,
    username: username || '',
    firstname: firstname || '',
    lastname: lastname || '',
    email: email || '',
    password: '',
    confirm_password: '',
    can_submit: true
  });

  let [confirmPass, setPassMessage] = useState({
    message: ''
  });

  let [confirmEmail, setEmailMessage] = useState({
    message: ''
  });

  let handleSubmit = event => {
    event.preventDefault();
    if (values.can_submit) {
      onSubmit(values);
    }
  };

  let handleChange = event => {
    // update state based on form input changes
    let { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  let handlePasswordConfirm = event => {
    // update state based on form input changes
    // handleChange(event);
    let { name, value } = event.target;
    const newState = { ...values, [name]: value };
    if (newState.password !== value || newState.confirm_password !== value) {
      setValues({ ...newState, can_submit: false });
      setPassMessage({ message: 'Passwords do not match.' });
    } else {
      setValues({ ...newState, can_submit: true });
      setPassMessage({});
    }
  };

  // from https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
  let validEmail = email => {
    // eslint-disable-next-line no-useless-escape
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  let handleEmailCheck = event => {
    let { name, value } = event.target;
    const newState = { ...values, [name]: value };
    if (!validEmail(newState.email)) {
      setValues({ ...newState, can_submit: false });
      setEmailMessage({ message: 'Invalid email.' });
    } else {
      setValues({ ...newState, can_submit: true });
      setEmailMessage({});
    }
  };

  return (
    <Card className={classes.centered}>
      <form onSubmit={event => handleSubmit(event)} autoComplete="off">
        <CardContent>
          <TextField
            type="text"
            name="username"
            label="Username"
            value={values.username}
            fullWidth
            margin="normal"
            onChange={event => handleChange(event)}
            InputProps={{
              readOnly: username == null ? false : true
            }}
          />
          <TextField
            type="password"
            name="password"
            label={update_password ? 'Update Password' : 'Password'}
            fullWidth
            margin="normal"
            value={values.password}
            onChange={event => handlePasswordConfirm(event)}
          />
          <TextField
            type="password"
            name="confirm_password"
            label="Confirm Password"
            fullWidth
            margin="normal"
            value={values.confirm_password}
            onChange={event => handlePasswordConfirm(event)}
          />
          {confirmPass.message && (
            <Typography color="error" variant="body1">
              {confirmPass.message}
            </Typography>
          )}
          <TextField
            type="text"
            name="firstname"
            label="First Name"
            fullWidth
            margin="normal"
            value={values.firstname}
            onChange={event => handleChange(event)}
          />
          <TextField
            type="text"
            name="lastname"
            label="Last Name"
            fullWidth
            margin="normal"
            value={values.lastname}
            onChange={event => handleChange(event)}
          />
          <TextField
            type="email"
            name="email"
            label="Email"
            fullWidth
            margin="normal"
            value={values.email}
            onChange={event => handleEmailCheck(event)}
          />
          {confirmEmail.message && (
            <Typography color="error" variant="body1">
              {confirmEmail.message}
            </Typography>
          )}
          {message && (
            <Typography
              variant="body1"
              color={message === 'user updated' ? 'primary' : 'error'}
            >
              {message}
            </Typography>
          )}
        </CardContent>
        <CardActions className={classes.centerChildren}>
          {/* <Button type="submit">Submit!</Button> */}
          <CircularLoadButton
            type="submit"
            isLoading={inProgress}
            isSuccess={isSuccess}
          >
            Submit!
          </CircularLoadButton>
          <Button component={RRLink} to={'/'}>
            Cancel
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

RegisterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  message: PropTypes.string,
  inProgress: PropTypes.bool
};

export default withStyles(styles)(RegisterForm);
