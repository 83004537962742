import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles, withTheme } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
// import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import WhereToVoteIcon from '@material-ui/icons/WhereToVote';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteQuestionModal from '../mui-forms/delete-question-form';

import { Link as RRLink } from 'react-router-dom';
import RequireAuthentication from '../../require-authenticate';
import queryString from 'query-string';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

import { makeAPICall } from '../../api';
import my_styles from '../../my-styles';
const styles = my_styles.MUIApp_styles;

let ListSurveys = ({
  classes,
  currentUser,
  match,
  location,
  history,
  enqueueSnackbar,
  theme
}) => {
  const atLeastSmall = useMediaQuery(theme.breakpoints.up('sm'));

  // an user message to be displayed, if any
  let page = 0;
  try {
    page = Number(queryString.parse(location.search).page);
    if (isNaN(page)) page = 0;
  } catch {}
  // console.log(page);

  const [message, updateMessage] = useState(null);
  const [surveyList, updateSurveyList] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [isRetrieved, setRetrieved] = useState(false);
  const [currentPage, setPage] = useState(page);
  const [areMoreSurveys, setMoreSurveys] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [qidToDelete, setQidToDelete] = useState(-1);

  let handleOpenModal = qid => {
    setQidToDelete(qid);
    setOpenModal(true);
  };
  let handleCloseModal = () => {
    setOpenModal(false);
  };

  /*
   * Builds table innards.
   * Uses users property (which gives the response body) to construct rows.
   */
  const SurveyList = ({ surveys }) => {
    if (!surveys) {
      return <></>;
    }

    const headers = currentUser.admin
      ? ['Vote', 'Question', 'Question Type', 'Options']
      : ['Vote', 'Question', 'Question Type'];
    const headerElements = headers.map(header => (
      <TableCell key={header}>{header}</TableCell>
    ));
    const TableHeaders = <TableRow>{headerElements}</TableRow>;

    const SurveyTableEntries = surveys.map(survey => {
      return (
        <TableRow key={JSON.stringify(survey.id)}>
          <TableCell>
            <Tooltip title={`Click to vote on question #${survey.id}`}>
              <IconButton
                component={RRLink}
                to={`/question/${survey.id}`}
                color="primary"
              >
                <WhereToVoteIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell>
            <Tooltip title={`Click to vote on question #${survey.id}`}>
              <Typography
                variant="body1"
                component={RRLink}
                to={`/question/${survey.id}`}
                style={{ textDecoration: 'none' }}
              >
                {survey.question}
              </Typography>
            </Tooltip>
            <Typography variant="caption">{survey.description}</Typography>
          </TableCell>
          <TableCell>
            {survey.type === 1 ? 'Single-option' : 'Multi-option'}
          </TableCell>
          {currentUser.admin == true && (
            <TableCell>
              <Tooltip title={`Delete question #${survey.id}`}>
                <IconButton onClick={() => handleOpenModal(survey.id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={`Edit question #${survey.id}`}>
                <IconButton
                  component={RRLink}
                  to={`/question/edit/${survey.id}`}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          )}
        </TableRow>
      );
    });

    const SurveyExpansionList = surveys.map(survey => {
      return (
        <ExpansionPanel key={JSON.stringify(survey.id)}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div>
              <Tooltip title={`Click to vote on question #${survey.id}`}>
                <IconButton
                  component={RRLink}
                  to={`/question/${survey.id}`}
                  color="primary"
                >
                  <WhereToVoteIcon />
                </IconButton>
              </Tooltip>
            </div>
            <div>
              <Typography
                align="left"
                variant="body1"
                // noWrap
                style={{
                  padding: '12px'
                  // overflow: 'hidden',
                  // whiteSpace: 'nowrap',
                  // textOverflow: 'ellipsis',
                  // maxWidth: '150px'
                }}
              >
                {survey.question}
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ display: 'block' }}>
            <div>
              <Typography align="center" variant="h6">
                {survey.question}
              </Typography>
            </div>
            <div>
              <Typography align="center" variant="body2">
                {survey.description}
              </Typography>
            </div>
            <div style={{ margin: '30px 10px 0 10px' }}>
              {currentUser.admin == true ? (
                <div style={{ display: 'flex' }}>
                  <div style={{ textAlign: 'left' }}>
                    <Tooltip title={`Click to vote on question #${survey.id}`}>
                      <Button
                        component={RRLink}
                        to={`/question/${survey.id}`}
                        color="primary"
                        variant="contained"
                      >
                        Vote!
                      </Button>
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      flexGrow: 1,
                      textAlign: 'right'
                    }}
                  >
                    <Tooltip title={`Delete question #${survey.id}`}>
                      <IconButton onClick={() => handleOpenModal(survey.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={`Edit question #${survey.id}`}>
                      <IconButton
                        component={RRLink}
                        to={`/question/edit/${survey.id}`}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              ) : (
                <div style={{ textAlign: 'center' }}>
                  <Tooltip title={`Click to vote on question #${survey.id}`}>
                    <Button
                      component={RRLink}
                      to={`/question/${survey.id}`}
                      color="primary"
                      variant="contained"
                    >
                      Vote!
                    </Button>
                  </Tooltip>
                </div>
              )}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    });

    // This is to update the path straight within the history object
    // as you page back and forth on the survey pages.
    const goToPage = page => {
      const path = history.location.pathname;
      history.push({
        path,
        search: `?page=${page}`
      });
      setPage(page);
    };

    const handlePageLeft = () => {
      goToPage(currentPage - 1);
      // setPage(currentPage - 1);
      setRetrieved(false);
    };

    const handlePageRight = () => {
      goToPage(currentPage + 1);
      // setPage(currentPage + 1);
      setRetrieved(false);
    };

    return (
      <>
        {atLeastSmall ? (
          <Table>
            <TableHead>{TableHeaders}</TableHead>
            <TableBody>{SurveyTableEntries}</TableBody>
          </Table>
        ) : (
          SurveyExpansionList
        )}
        <div>
          <div className={classes.paginateButtons}>
            <IconButton disabled={currentPage < 1} onClick={handlePageLeft}>
              <ChevronLeftIcon />
            </IconButton>
            <IconButton disabled={!areMoreSurveys} onClick={handlePageRight}>
              <ChevronRightIcon />
            </IconButton>
          </div>
          {currentUser.admin == true && (
            <div className={classes.rightAlignButton}>
              <Tooltip title={`Add a new question!`}>
                <Fab
                  color="primary"
                  aria-label="Add"
                  className={classes.fab}
                  component={RRLink}
                  to={`/question/new`}
                >
                  <AddIcon />
                </Fab>
              </Tooltip>
            </div>
          )}
        </div>
      </>
    );
  };

  let listSurveys = async page => {
    updateMessage(null);
    setInProgress(true);

    let res;
    try {
      res = await makeAPICall('GET', '/api/question?page=' + page);
      let body = await res.json();
      setInProgress(false);
      updateMessage(body.message);
      updateSurveyList(body.users);
      setMoreSurveys(body.has_more);
    } catch {
      setInProgress(false);
      if (res.status === 500) updateMessage('Unable to connect to server');
    }
  };

  if (!isRetrieved) {
    listSurveys(currentPage);
    setRetrieved(true);
  }

  return (
    <div className={classes.root}>
      <Typography align="center" variant="h5" gutterBottom>
        Available Surveys
      </Typography>
      {inProgress && <LinearProgress />}
      {message ? (
        <div>
          <Typography align="center" variant="body1" gutterBottom>
            {message}
          </Typography>
        </div>
      ) : null}

      <SurveyList surveys={surveyList} />
      <DeleteQuestionModal
        qidToDelete={qidToDelete}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        location={location}
        setRetrieved={setRetrieved}
        enqueueSnackbar={enqueueSnackbar}
      />
    </div>
  );
};

ListSurveys.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired
};

export default withStyles(styles)(
  RequireAuthentication(withTheme()(ListSurveys))
);
