import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import { Link as RRLink } from 'react-router-dom';
import CircularLoadButton from './components/MUICircularLoadButton';
import my_styles from './my-styles';
const styles = my_styles.MUIApp_styles;

function ControlledModal({
  classes,
  qidToDelete,
  openModal,
  handleCloseModal,
  onClick,
  inProgress,
  submitSuccess
}) {
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick
      open={openModal}
      onClose={handleCloseModal}
    >
      <div className={classes.paper}>
        <Typography variant="h5" align="center" id="modal-title">
          Are you sure?
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          id="simple-modal-description"
        >
          Do you really want to delete question #{qidToDelete}?
        </Typography>
        <div className={[classes.centerChildren, classes.centerText].join(' ')}>
          <CircularLoadButton
            type="submit"
            isLoading={inProgress}
            isSuccess={submitSuccess}
            onClick={onClick}
          >
            Delete
          </CircularLoadButton>
          <Button onClick={handleCloseModal}>Cancel</Button>
        </div>
      </div>
    </Modal>
  );
}

ControlledModal.propTypes = {
  qidToDelete: PropTypes.number.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
  inProgress: PropTypes.bool.isRequired,
  submitSuccess: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default withStyles(styles)(ControlledModal);
