import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { Link as RRLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CircularLoadButton from '../../components/MUICircularLoadButton';
import DeleteIcon from '@material-ui/icons/Delete';
import my_styles from '../../my-styles';
const styles = my_styles.MUIApp_styles;

let CreateQuestionForm = ({
  updateQuestion = false,
  classes,
  onSubmit,
  inProgress,
  submitSuccess,
  message,
  formFields
}) => {
  let [values, setValues] = useState({
    question: formFields ? formFields.question : '',
    description: formFields ? formFields.description : 'Please choose one.',
    type: formFields ? formFields.type : 1,
    choices: formFields
      ? formFields.choices
        ? formFields.choices.map(choice => choice.description)
        : []
      : []
  });
  let handleSubmit = event => {
    event.preventDefault();
    if (updateQuestion || !submitSuccess) {
      onSubmit(values);
    }
  };
  let handleChange = event => {
    // update state based on form input changes
    let { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  let handleAnswerChoiceChange = event => {
    let { name, value } = event.target;
    let choicesCopy = values.choices;
    choicesCopy[name] = value;
    setValues({ ...values, choices: [...choicesCopy] });
  };
  let handleAddAnswerChoice = () => {
    setValues({ ...values, choices: [...values.choices, ''] });
  };
  let handleDeleteAnswerChoice = deleteIndex => {
    setValues({
      ...values,
      choices: [
        ...values.choices.filter((choice, index) => index !== deleteIndex)
      ]
    });
  };

  return (
    <Card className={classes.centered}>
      <form onSubmit={event => handleSubmit(event)} autoComplete="off">
        <CardContent>
          <TextField
            required
            multiline
            variant="outlined"
            type="text"
            name="question"
            label="Question"
            value={values.question}
            fullWidth
            margin="normal"
            onChange={event => handleChange(event)}
          />
          <TextField
            required
            multiline
            variant="outlined"
            rows="9"
            type="text"
            name="description"
            label="Description"
            fullWidth
            margin="normal"
            value={values.description}
            onChange={event => handleChange(event)}
          />
          <div>
            {values.choices.length > 0 && (
              <div className={classes.marginTop}>
                <Typography variant="h5" align="center">
                  Answer Choices
                </Typography>
                {values.choices.map((choice, index) => {
                  return (
                    <TextField
                      required
                      type="text"
                      name={`${index}`}
                      // label={`#${index + 1}`}
                      key={index}
                      value={choice}
                      fullWidth
                      margin="none"
                      onChange={event => handleAnswerChoiceChange(event)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Typography variant="caption">
                              {`#${index + 1}`}
                            </Typography>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              title={`Delete answer choice #${index + 1}`}
                            >
                              <IconButton
                                style={{ padding: '3px' }}
                                onClick={() => {
                                  handleDeleteAnswerChoice(index);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        )
                      }}
                    />
                  );
                })}
              </div>
            )}
          </div>
          <div className={classes.marginTop}>
            <Button onClick={handleAddAnswerChoice} color="secondary">
              + Add new answer choice
            </Button>
          </div>
          {message && (
            <Typography color="error" variant="body1" align="center">
              {message}
            </Typography>
          )}
        </CardContent>
        <CardActions className={classes.centerChildren}>
          {/* <Button type="submit">Submit!</Button> */}
          <CircularLoadButton
            type="submit"
            isLoading={inProgress}
            isSuccess={submitSuccess}
          >
            Publish
          </CircularLoadButton>
          <Button component={RRLink} to={`/questions`}>
            {submitSuccess ? 'Go To Surveys' : 'Cancel'}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

// CreateQuestionForm.propTypes = {
//   onSubmit: PropTypes.func.isRequired,
//   message: PropTypes.string,
//   inProgress: PropTypes.bool
// };

export default withStyles(styles)(CreateQuestionForm);
