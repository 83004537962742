import React from 'react';
// import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Link as RRLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';
// import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import CircularProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import CircularLoadButton from '../../components/MUICircularLoadButton';
import my_styles from '../../my-styles';
const styles = my_styles.MUIApp_styles;

function VotingForm({
  classes,
  onSubmit,
  question,
  description,
  message,
  answerList,
  isSubmitting,
  submitSuccess,
  previousVote
}) {
  let answerListStart = answerList.map(answer => {
    return (
      <FormControlLabel
        value={JSON.stringify(answer.id)}
        control={<Radio color="secondary" />}
        label={answer.description}
        labelPlacement="start"
        key={answer.id}
      />
    );
  });

  // console.log(JSON.stringify(previousVote));

  const [value, setValue] = React.useState(JSON.stringify(previousVote));

  // console.log(value);

  function handleChange(event) {
    // console.log(event.target.value);
    setValue(event.target.value);
  }

  let handleSubmit = event => {
    event.preventDefault();
    onSubmit(value);
  };

  return (
    <div className={classes.root}>
      <Card className={classes.centered}>
        <form onSubmit={event => handleSubmit(event)} autoComplete="off">
          <CardContent>
            <Typography variant="h6" align="center">
              {question}
            </Typography>
            <Typography variant="body1" align="center">
              {description}
            </Typography>
            <FormControl
              align="center"
              component="fieldset"
              className={classes.formControl}
            >
              <RadioGroup
                aria-label={`survey question`}
                name="survey"
                className={classes.group}
                value={value}
                onChange={handleChange}
              >
                {answerListStart}
              </RadioGroup>
            </FormControl>
          </CardContent>
          <CardActions className={classes.centerChildren}>
            <CircularLoadButton
              type="submit"
              isLoading={isSubmitting}
              isSuccess={submitSuccess}
            >
              VOTE!
            </CircularLoadButton>
            <Button component={RRLink} to={'/questions'}>
              Back To Surveys
            </Button>
          </CardActions>
        </form>
      </Card>
    </div>
  );
}

VotingForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  submitSuccess: PropTypes.bool.isRequired
};

export default withStyles(styles)(VotingForm);
