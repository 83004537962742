import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Switch as RRSwitch } from 'react-router';
// import api_url_path from './api-url-path';
import jwt from 'jsonwebtoken';
import { SnackbarProvider, withSnackbar } from 'notistack';

// socket.io setup
// import './setupProxy';
import { SocketIONotifications } from './realtime/notifications';

import { tokenKey, currentUserKey, themeKey } from './credentials';

// All possible tabs
import Welcome from './tabs/mui-tabs/welcome';
import RegisterUser from './tabs/mui-tabs/register';
import LoginUser from './tabs/mui-tabs/login';
import UserProfile from './tabs/mui-tabs/profile';
import ListUsers from './tabs/mui-tabs/list-users';
import PageNotFound from './tabs/mui-tabs/page-not-found';
import EditProfile from './tabs/mui-tabs/edit-profile';
import ListSurveys from './tabs/mui-tabs/list-surveys';
import VoteQuestion from './tabs/mui-tabs/vote-question';
import CreateQuestion from './tabs/mui-tabs/create-question';
import EditQuestion from './tabs/mui-tabs/edit-question';

// Forms
// import RegisterForm from './tabs/mui-forms/registration-form';
// import LoginForm from './tabs/mui-forms/login-form';

import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import TabChooser from './components/MUITabChooser';
import my_styles from './my-styles';
const darkTheme = my_styles.darkTheme;
const defaultTheme = my_styles.defaultTheme;

// ------------------------------------------------------------------

let CloseSnackbar = () => {};

let App = ({ enqueueSnackbar, closeSnackbar }) => {
  CloseSnackbar = closeSnackbar;
  let themeIsDark = JSON.parse(localStorage.getItem(themeKey)) || false;
  let [isDark, switchThemeFunc] = useState(themeIsDark);
  let initialUser = JSON.parse(localStorage.getItem(currentUserKey)); // code to read user from localStorage
  const token = localStorage.getItem(tokenKey); // code to read user from localStorage
  const decoded = jwt.decode(token);

  let [currentUser, updateUser] = useState(() => {
    if (initialUser) {
      if (
        !decoded ||
        (initialUser &&
          // eslint-disable-next-line eqeqeq
          (initialUser.username != decoded.data.username ||
            // eslint-disable-next-line eqeqeq
            initialUser.id != decoded.data.id))
      ) {
        return { ...initialUser, authenticated: false };
      } else {
        return {
          ...initialUser,
          authenticated: true,
          admin: decoded.data.admin
        };
      }
    }
    return { authenticated: false };
  });

  return (
    <MuiThemeProvider theme={isDark ? darkTheme : defaultTheme}>
      <SocketIONotifications
        enqueueSnackbar={enqueueSnackbar}
        currentUser={currentUser}
      />
      <CssBaseline />
      <Router basename={`${process.env.PUBLIC_URL}`}>
        <RRSwitch>
          <Route
            exact
            path="/login"
            label="Login"
            hideIf={true}
            key="Login"
            render={props => (
              <LoginUser
                currentUser={currentUser}
                updateUser={updateUser}
                enqueueSnackbar={enqueueSnackbar}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/register"
            label="Register"
            hideIf={true}
            key="Register"
            render={props => (
              <RegisterUser
                currentUser={currentUser}
                updateUser={updateUser}
                enqueueSnackbar={enqueueSnackbar}
                {...props}
              />
            )}
          />
          {currentUser.authenticated && (
            <Route
              exact
              path={`/profile/edit/${currentUser.id}`}
              label="Edit Profile"
              hideIf={true}
              key="Edit Profile"
              render={props => (
                <EditProfile
                  currentUser={currentUser}
                  updateUser={updateUser}
                  enqueueSnackbar={enqueueSnackbar}
                  {...props}
                />
              )}
            />
          )}
          <Route
            exact
            path="/logout"
            key="Logout"
            render={() => {
              localStorage.removeItem(tokenKey);
              localStorage.removeItem(currentUserKey);
              // force hard refresh
              window.location.href = `${process.env.PUBLIC_URL}/`;
              return (
                <Typography align="center" variant="body1">
                  Come back soon!
                </Typography>
              );
            }}
          />
          <Route>
            <TabChooser
              changeTheme={switchThemeFunc}
              currentUser={currentUser}
              updateUser={updateUser}
              themeSwitchEngaged={isDark}
            >
              <Route
                exact
                path="/"
                label="Welcome"
                hideIf={false}
                component={Welcome}
                render={props => (
                  <Welcome
                    currentUser={currentUser}
                    updateUser={updateUser}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/questions"
                label="Surveys"
                hideIf={!currentUser.authenticated}
                render={props => (
                  <ListSurveys
                    currentUser={currentUser}
                    updateUser={updateUser}
                    enqueueSnackbar={enqueueSnackbar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path={`/question/new`}
                label="New Question"
                hideIf={true}
                key="New Question"
                render={props => (
                  <CreateQuestion
                    currentUser={currentUser}
                    updateUser={updateUser}
                    enqueueSnackbar={enqueueSnackbar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path={`/question/edit/:qid`}
                label="Edit Question"
                hideIf={true}
                key="Edit Question"
                render={props => (
                  <EditQuestion
                    currentUser={currentUser}
                    updateUser={updateUser}
                    enqueueSnackbar={enqueueSnackbar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path={`/question/:qid`}
                label="Question"
                hideIf={true}
                key="Question"
                render={props => (
                  <VoteQuestion
                    currentUser={currentUser}
                    updateUser={updateUser}
                    enqueueSnackbar={enqueueSnackbar}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/list-users"
                label="List Users"
                hideIf={!currentUser.authenticated || !currentUser.admin}
                render={props => (
                  <ListUsers
                    currentUser={currentUser}
                    updateUser={updateUser}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/profile"
                label="Profile"
                hideIf={!currentUser.authenticated}
                render={props => (
                  <UserProfile
                    currentUser={currentUser}
                    updateUser={updateUser}
                    {...props}
                  />
                )}
              />
              <Route
                label="Page Not Found"
                hideIf={true}
                component={PageNotFound}
                render={props => (
                  <PageNotFound
                    currentUser={currentUser}
                    updateUser={updateUser}
                    {...props}
                  />
                )}
              />
            </TabChooser>
          </Route>
        </RRSwitch>
      </Router>
    </MuiThemeProvider>
  );
};

App.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  closeSnackbar: PropTypes.func.isRequired
};

// export default App;

const MyApp = withSnackbar(App);

function IntegrationNotistack() {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      action={key => (
        <IconButton
          onClick={() => {
            CloseSnackbar(key);
          }}
          style={{ color: 'white' }}
        >
          <CloseIcon />
        </IconButton>
      )}
    >
      <MyApp />
    </SnackbarProvider>
  );
}

export default IntegrationNotistack;
