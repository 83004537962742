import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Forms
import CreateQuestionForm from '../mui-forms/create-question-form';
import RequireAuthentication from '../../require-authenticate';
import RequireAdmin from '../../require-admin';

import { withStyles } from '@material-ui/core/styles';
import { Typography, LinearProgress } from '@material-ui/core';
import { makeAPICall } from '../../api';
import my_styles from '../../my-styles';
// import createQuestionForm from '../mui-forms/create-question-form';
const styles = my_styles.MUIApp_styles;

// ---------------------------------------------------------------------------

let EditQuestion = ({
  classes,
  currentUser,
  updateUser,
  location,
  match,
  enqueueSnackbar
}) => {
  let [message, setMessage] = useState('');
  let [inProgress, setInProgress] = useState(false);
  let [submitSuccess, setSubmitSuccess] = useState(false);
  let [triedOnce, setTriedOnce] = useState(false);
  let [fetchMessage, setFetchMessage] = useState('');
  let [fetchedQuestion, setFetchedQuestion] = useState(false);
  let [fetchInProgress, setFetchInProgress] = useState(true);
  let [formFields, setFormFields] = useState({});

  const qid = match && match.params && match.params.qid;

  console.log(fetchInProgress);

  let fetchQuestion = async () => {
    setFetchMessage('');
    setFetchInProgress(true);
    try {
      let res = await makeAPICall('GET', `/api/question/${qid}`);
      let body = await res.json();
      setFetchInProgress(false);
      if (res.status === 200) {
        console.log(body);
        setFormFields(body); // order of sets matters so fields actually propogate to form
        setFetchedQuestion(true);
        return;
      }
      setFetchedQuestion(true);
      setFetchMessage(body.message);
    } catch (err) {
      setFetchInProgress(false);
      setFetchedQuestion(true);
      setMessage('Unable to connect to server.');
    }
  };

  let editQuestion = async values => {
    setMessage('');
    setInProgress(true);
    try {
      if (values.choices.length < 1) {
        setMessage('You must include at least one answer choice.');
        setInProgress(false);
        setTimeout(() => {
          setMessage('');
        }, 5000);
        return;
      }
      let res = await makeAPICall('PUT', `/api/question/${qid}`, values);
      let body = await res.json();
      console.log(body);
      setInProgress(false);
      if (res.status === 200) {
        setSubmitSuccess(true);
        enqueueSnackbar(
          `Find your question at ${process.env.PUBLIC_URL}/question/${qid}`,
          {
            variant: 'info',
            autoHideDuration: 6000
          }
        );
        enqueueSnackbar(`Edited question #${qid}`, {
          variant: 'success',
          autoHideDuration: 4000
        });
        return;
      }
    } catch (err) {
      setInProgress(false);
      setMessage('Unable to connect to server.');
    }
  };

  if (!triedOnce) {
    fetchQuestion();
    setTriedOnce(true);
  }

  return (
    <div className={classes.root}>
      <Typography align="center" variant="h5" gutterBottom>
        Edit Question #{qid}
      </Typography>
      {fetchedQuestion ? (
        <CreateQuestionForm
          onSubmit={editQuestion}
          message={message}
          inProgress={inProgress}
          submitSuccess={submitSuccess}
          updateQuestion={true}
          formFields={formFields}
        />
      ) : (
        <>
          <LinearProgress />
          <Typography align="center" variant="h5" gutterBottom>
            {fetchMessage}
          </Typography>
        </>
      )}
    </div>
  );
};

EditQuestion.propTypes = {
  match: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired
};

export default withStyles(styles)(
  RequireAuthentication(RequireAdmin(EditQuestion))
);
