import io from 'socket.io-client'; // uses stand-alone build
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

// Simple test of connecting with server:
// let simple = io.connect();

// simple.on('server:pong', msg => {
//   console.log('server ponged');
// });

// simple.emit('client:ping', 'hi');

console.log('Setting up notification alert routing with socket.io');

const socketIoUrl = `${window.location.origin}`;

// authentication
const authSocket = io.connect(`${socketIoUrl}/auth`, {
  path: `${process.env.PUBLIC_URL}/api/socket.io`
});

let SocketIONotifications = ({ enqueueSnackbar, currentUser }) => {
  useEffect(() => {
    if (currentUser.admin) {
      authSocket.on('newuser', msg => {
        // ... extract information from msg
        console.log('NEW USER MESSAGE: ' + JSON.stringify(msg));
        enqueueSnackbar(msg, {
          variant: 'info',
          autoHideDuration: 10000
        });
      });
    }
    return () => authSocket.removeAllListeners();
  }, [enqueueSnackbar, currentUser]);

  // authSocket.on('updateuser', msg => {
  //   console.log('NEW USER MESSAGE: ' + msg);
  //   // ....
  // });

  return <></>;
};

SocketIONotifications.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired
};

// authentication
const voteSocket = io.connect(`${socketIoUrl}/votes`, {
  path: `${process.env.PUBLIC_URL}/api/socket.io`
});

let SocketIOSubscribeToQuestion = (qid, voteUpdateFunction) => {
  voteSocket.on('voteupdate', () => voteUpdateFunction());

  voteSocket.emit('subscribe', {
    // Since rooms are server-side abstractions, need to give qid for server to deal with rooms
    qid
  });

  // unsubscribe_later
  return async () => {
    await voteSocket.emit('unsubscribe', {
      // Since rooms are server-side abstractions, need to give qid for server to deal with rooms
      qid
    });
  }; // for closing connection
};

export { SocketIONotifications, SocketIOSubscribeToQuestion };
