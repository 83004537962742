import React, { useState } from 'react';
import PropTypes from 'prop-types';

// The Form
import RegisterForm from '../mui-forms/registration-form';

import { tokenKey, currentUserKey } from '../../credentials';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router';
import { makeAPICall } from '../../api';
import my_styles from '../../my-styles';
const styles = my_styles.MUIApp_styles;

let RegisterUser = ({ classes, currentUser, updateUser, enqueueSnackbar }) => {
  let [message, setMessage] = useState('');
  let [inProgress, setInProgress] = useState(false);

  if (currentUser.authenticated) {
    console.log(`Redirecting to home page`);
    return <Redirect to={`/`} />;
  }

  let registerUser = async values => {
    setMessage('');
    setInProgress(true);
    try {
      let res = await makeAPICall('POST', '/api/users', values);
      let body = await res.json();
      setInProgress(false);
      setMessage(body.message);
      if (res.status === 200) {
        localStorage.setItem(tokenKey, body.token);
        localStorage.setItem(currentUserKey, JSON.stringify(body.user));
        updateUser({ ...body.user, authenticated: true, admin: false });
        enqueueSnackbar(`${body.user.username} is now registered!`, {
          variant: 'success',
          autoHideDuration: 4000
        });
      }
    } catch (err) {
      setInProgress(false);
      setMessage('Unable to connect to server.');
    }
  };
  return (
    <div className={classes.root}>
      <Typography align="center" variant="h5" gutterBottom>
        Please Register for a New Account
      </Typography>
      <RegisterForm
        onSubmit={registerUser}
        message={message}
        inProgress={inProgress}
      />
    </div>
  );
};

RegisterUser.propTypes = {
  currentUser: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired
};

export default withStyles(styles)(RegisterUser);
