import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import my_styles from '../../my-styles';
const styles = my_styles.MUIApp_styles;

let PageNotFound = ({ classes, updateUser }) => {
  return (
    <div className={classes.root}>
      <Typography align="center" variant="h5" gutterBottom>
        Oh No! We can't find this page.
      </Typography>
    </div>
  );
};

export default withStyles(styles)(PageNotFound);
