import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import { Redirect } from 'react-router';
import { makeAPICall } from '../../api';

import ControlledModal from '../../controlled-modal';
import my_styles from '../../my-styles';
const styles = my_styles.MUIApp_styles;

let QuestionDeleteModal = ({
  classes,
  currentUser,
  updateUser,
  qidToDelete,
  handleCloseModal,
  openModal,
  setRetrieved,
  enqueueSnackbar
}) => {
  let [message, setMessage] = useState('');
  let [inProgress, setInProgress] = useState(false);
  let [submitSuccess, setSubmitSuccess] = useState(false);

  let deleteQuestion = async () => {
    setMessage('');
    setInProgress(true);
    try {
      let res = await makeAPICall('DELETE', `/api/question/${qidToDelete}`);
      let body = await res.json();
      // console.log(body);
      setMessage(body.message);
      if (res.status === 200) {
        setInProgress(false);
        setSubmitSuccess(true);
        setRetrieved ? setRetrieved(false) : window.location.reload();
        handleCloseModal();
        setSubmitSuccess(false);
        enqueueSnackbar(`Deleted question #${qidToDelete}`, {
          variant: 'success',
          autoHideDuration: 4000
        });
      }
    } catch (err) {
      setInProgress(false);
      setMessage('Unable to connect to server.');
    }
  };
  return (
    <div className={classes.root}>
      <ControlledModal
        qidToDelete={qidToDelete}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        onClick={deleteQuestion}
        inProgress={inProgress}
        submitSuccess={submitSuccess}
      />
      {message ? (
        <div>
          <Typography align="center" variant="body1" gutterBottom>
            {message}
          </Typography>
        </div>
      ) : null}
    </div>
  );
};

QuestionDeleteModal.propTypes = {
  qidToDelete: PropTypes.number.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
  setRetrieved: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired
};

export default withStyles(styles)(QuestionDeleteModal);
