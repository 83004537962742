import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Link as RRLink } from 'react-router-dom';
import my_styles from '../../my-styles';
const styles = my_styles.MUIApp_styles;

let Welcome = ({ classes }) => (
  <div className={classes.root}>
    <Typography align="center" variant="h5" gutterBottom>
      Welcome to Surveyor
    </Typography>
    <Typography align="center" variant="body1">
      {/* Click on a tab. You should implement the{' '}
      <Link href="/login">"Login"</Link> tab, the{' '}
      <Link href="/profile">"Profile"</Link> tab, and the{' '}
      <Link href="/list-users">"List Users"</Link> tab. */}
      Feel free to checkout the{' '}
      <Link component={RRLink} to={`/questions`}>
        available surveys
      </Link>
      .
    </Typography>
  </div>
);

export default withStyles(styles)(Welcome); // apply HOC
