import React, { useEffect } from 'react';
// import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ReactVisGrapher from '../../components/ReactVisGrapher';

import { SocketIOSubscribeToQuestion } from '../../realtime/notifications';

import { withStyles } from '@material-ui/core/styles';
import my_styles from '../../my-styles';
const styles = my_styles.MUIApp_styles;

function VotingResults({
  classes,
  answerList,
  userVoted = false,
  voteTotals = [],
  updateVotes,
  questionID
}) {
  useEffect(() => {
    // THIS IS DONE ON MOUNT:
    // console.log(`MOUNTING QUESTION VOTING FOR ${qid}`);
    if (userVoted) {
      let unsubscribe_later = SocketIOSubscribeToQuestion(
        questionID,
        updateVotes
      ); // this also subscribes
      // THIS IS DONE ON UNMOUNT!!!
      return async () => {
        // console.log('UNMOUNTING');
        unsubscribe_later();
      };
    }
  }, [userVoted]);

  let showResults = voteTotals.length > 0;

  let answerResults = answerList.map((answer, index) => {
    let exists = voteTotals.filter(entry => {
      return answer.id === entry.choice;
    })[0]
      ? true
      : false;
    return {
      x: answer.description,
      y: exists
        ? voteTotals.filter(entry => {
            return answer.id === entry.choice;
          })[0].count
        : 0
    };
  });
  // console.log('Answer counts:');
  // console.log(answerResults);

  return (
    <div className={classes.root}>
      <Card className={classes.centered}>
        <CardContent>
          <Typography variant="h6" align="center">
            Results
          </Typography>
          {!userVoted && (
            <Typography variant="body2" align="center" color="error">
              You must vote prior to viewing the results.
            </Typography>
          )}
          {showResults && <ReactVisGrapher data={answerResults} />}
        </CardContent>
      </Card>
    </div>
  );
}

export default withStyles(styles)(VotingResults);
