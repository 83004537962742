import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RRLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularLoadButton from '../../components/MUICircularLoadButton';
import my_styles from '../../my-styles';
const styles = my_styles.MUIApp_styles;

let LoginForm = ({ classes, onSubmit, inProgress, message }) => {
  let [values, setValues] = useState({ username: '', password: '' });
  let handleSubmit = event => {
    event.preventDefault();
    onSubmit(values);
  };
  let handleChange = event => {
    // update state based on form input changes
    let { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <Card className={classes.centered}>
      <form onSubmit={event => handleSubmit(event)} autoComplete="off">
        <CardContent>
          <TextField
            required
            type="text"
            name="username"
            label="Username"
            value={values.username}
            fullWidth
            margin="normal"
            onChange={event => handleChange(event)}
          />
          {message && (
            <Typography color="error" variant="body1">
              {message}
            </Typography>
          )}
          <TextField
            required
            type="password"
            name="password"
            label="Password"
            fullWidth
            margin="normal"
            value={values.password}
            onChange={event => handleChange(event)}
          />
        </CardContent>
        <CardActions className={classes.centerChildren}>
          {/* <Button type="submit">Submit!</Button> */}
          <CircularLoadButton type="submit" isLoading={inProgress}>
            Submit!
          </CircularLoadButton>
          <Button component={RRLink} to={'/'}>
            Cancel
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  message: PropTypes.string,
  inProgress: PropTypes.bool
};

export default withStyles(styles)(LoginForm);
