import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Forms
import CreateQuestionForm from '../mui-forms/create-question-form';
import RequireAuthentication from '../../require-authenticate';
import RequireAdmin from '../../require-admin';

import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// import { Link as RRLink } from 'react-router-dom';
// import { Redirect } from 'react-router';
import { makeAPICall } from '../../api';
import my_styles from '../../my-styles';
// import createQuestionForm from '../mui-forms/create-question-form';
const styles = my_styles.MUIApp_styles;

// ---------------------------------------------------------------------------

let CreateQuestion = ({
  classes,
  currentUser,
  updateUser,
  location,
  enqueueSnackbar
}) => {
  let [message, setMessage] = useState('');
  let [inProgress, setInProgress] = useState(false);
  let [submitSuccess, setSubmitSuccess] = useState(false);
  let [questionID, updateQuestionID] = useState(0);

  console.log(`creating question ${questionID}`);

  let createQuestion = async values => {
    setMessage('');
    setInProgress(true);
    try {
      if (values.choices.length < 1) {
        setMessage('You must include at least one answer choice.');
        setInProgress(false);
        setTimeout(() => {
          setMessage('');
        }, 5000);
        return;
      }
      let res = await makeAPICall('POST', '/api/question', values);
      let body = await res.json();
      setInProgress(false);
      if (res.status === 200) {
        console.log(body);
        updateQuestionID(body.id);
        setSubmitSuccess(true);
        enqueueSnackbar(`Find your question at /question/${body.id}`, {
          variant: 'info',
          autoHideDuration: 6000
        });
        enqueueSnackbar(`Created question #${body.id}!`, {
          variant: 'success',
          autoHideDuration: 4000
        });
        return;
      }
    } catch (err) {
      setInProgress(false);
      setMessage('Unable to connect to server.');
    }
  };
  return (
    <div className={classes.root}>
      <Typography align="center" variant="h5" gutterBottom>
        Add a New Question
      </Typography>
      <CreateQuestionForm
        onSubmit={createQuestion}
        message={message}
        inProgress={inProgress}
        submitSuccess={submitSuccess}
      />
    </div>
  );
};

CreateQuestion.propTypes = {
  location: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired
};

export default withStyles(styles)(
  RequireAuthentication(RequireAdmin(CreateQuestion))
);
