import React, { useState } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import RequireAuthentication from '../../require-authenticate';
import RequireAdmin from '../../require-admin';
import queryString from 'query-string';

import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

import { makeAPICall } from '../../api';
import my_styles from '../../my-styles';
const styles = my_styles.MUIApp_styles;

let ListUsers = ({ classes, currentUser, history, location, theme }) => {
  const atLeastMedium = useMediaQuery(theme.breakpoints.up('md'));
  let page = 0;
  try {
    page = Number(queryString.parse(location.search).page);
    if (isNaN(page)) page = 0;
  } catch {}
  // an user message to be displayed, if any
  const [message, updateMessage] = useState(null);
  const [userList, updateUserList] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [isRetrieved, setRetrieved] = useState(false);
  const [currentPage, setPage] = useState(page);
  const [areMoreUsers, setMoreUsers] = useState(false);

  if (!currentUser) {
    return (
      <div className={classes.root}>
        <Typography align="center" variant="h5" gutterBottom>
          Login or Register to view this page.
        </Typography>
        <Typography align="center" variant="body1" gutterBottom>
          Admin access required.
        </Typography>
      </div>
    );
  }

  /*
   * Builds table innards.
   * Uses users property (which gives the response body) to construct rows.
   */
  const UserList = ({ users }) => {
    if (!users) {
      return <></>;
    }

    const headers = [
      'Id',
      'Username',
      'First Name',
      'Last Name',
      'Email',
      'Admin'
    ];
    const headerElements = headers.map(header => (
      <TableCell key={header}>{header}</TableCell>
    ));
    const TableHeaders = <TableRow>{headerElements}</TableRow>;

    const UserTableEntries = users.map(user => {
      return (
        <TableRow key={JSON.stringify(user.id)}>
          <TableCell>{user.id}</TableCell>
          <TableCell>{user.username}</TableCell>
          <TableCell>{user.firstname}</TableCell>
          <TableCell>{user.lastname}</TableCell>
          <TableCell>{user.email}</TableCell>
          <TableCell>
            {
              <Tooltip
                title={
                  `${user.username} ` +
                  (user.admin == true ? `is` : `isn't`) +
                  ` an admin`
                }
              >
                {user.admin == true ? (
                  <Icon>lock_open</Icon>
                ) : (
                  <Icon>lock</Icon>
                )}
              </Tooltip>
            }
          </TableCell>
        </TableRow>
      );
    });

    const UserExpansionList = users.map(user => {
      return (
        <ExpansionPanel key={user.id}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              align="left"
              variant="body1"
              style={{ display: 'inline-block', width: '50%' }}
            >
              <em>ID:</em> {user.id}
            </Typography>
            <Typography
              align="left"
              variant="body1"
              style={{ display: 'inline-block', width: '50%' }}
            >
              {user.username}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ display: 'block' }}>
            <div>
              <Typography variant="body2">ID: {user.id}</Typography>
            </div>
            <div>
              <Typography variant="body2">Username: {user.username}</Typography>
            </div>
            <div>
              <Typography variant="body2">
                First Name: {user.firstname}
              </Typography>
            </div>
            <div>
              <Typography variant="body2">
                Last Name: {user.lastname}
              </Typography>
            </div>
            <div>
              <Typography variant="body2">Email: {user.email}</Typography>
            </div>
            <div>
              <Typography variant="body2">
                Admin Status: <strong>{user.admin ? 'true' : 'false'}</strong>
              </Typography>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    });

    const goToPage = page => {
      const path = history.location.pathname;
      history.push({
        path,
        search: `?page=${page}`
      });
      setPage(page);
    };

    const handlePageLeft = () => {
      goToPage(currentPage - 1);
      // setPage(currentPage - 1);
      setRetrieved(false);
    };

    const handlePageRight = () => {
      goToPage(currentPage + 1);
      // setPage(currentPage + 1);
      setRetrieved(false);
    };

    return (
      <>
        {atLeastMedium ? (
          <Table>
            <TableHead>{TableHeaders}</TableHead>
            <TableBody>{UserTableEntries}</TableBody>
          </Table>
        ) : (
          UserExpansionList
        )}
        <div>
          <IconButton disabled={currentPage < 1} onClick={handlePageLeft}>
            <ChevronLeftIcon />
          </IconButton>
          <IconButton disabled={!areMoreUsers} onClick={handlePageRight}>
            <ChevronRightIcon />
          </IconButton>
        </div>
      </>
    );
  };

  let listUsers = async page => {
    updateMessage(null);
    setInProgress(true);

    let res;
    try {
      res = await makeAPICall('GET', '/api/users?page=' + page);
      let body = await res.json();
      setInProgress(false);
      updateMessage(body.message);
      updateUserList(body.users);
      setMoreUsers(body.has_more);
    } catch {
      setInProgress(false);
      if (res.status === 500) updateMessage('Unable to connect to server');
    }
  };

  if (!isRetrieved) {
    listUsers(currentPage);
    setRetrieved(true);
  }

  return (
    <div className={classes.root}>
      <Typography align="center" variant="h5" gutterBottom>
        Registered Users
      </Typography>
      {inProgress && <LinearProgress />}
      {message ? (
        <div>
          <Typography align="center" variant="body1" gutterBottom>
            {message}
          </Typography>
        </div>
      ) : null}

      <UserList users={userList} />
    </div>
  );
};

export default withStyles(styles)(
  RequireAuthentication(RequireAdmin(withTheme()(ListUsers)))
);
