import React, { useEffect, useState } from 'react';
import { Link as RRLink } from 'react-router-dom';
import { withRouter, Switch as RRSwitch } from 'react-router';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Switch from '@material-ui/core/Switch';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

// Drawer:
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Tooltip, Button } from '@material-ui/core';

import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

import { themeKey } from '../credentials';

import my_styles from '../my-styles';
const styles = my_styles.MUITabChooser_styles;

function TabChooser({
  changeTheme,
  currentUser,
  updateUser,
  classes,
  children,
  location,
  history,
  themeSwitchEngaged,
  theme
}) {
  const atLeastSmall = useMediaQuery(theme.breakpoints.up('sm'));

  let tabs = children.filter(child => child && !child.props.hideIf);
  // console.log(children);

  const findTabFromPath = location_prop => {
    const selectedTab = tabs.findIndex(
      tab => tab.props.path === location_prop.pathname
    );
    return selectedTab !== -1 ? selectedTab : 0;
  };

  const [currentTab, selectTab] = useState(() => {
    // Ensure that the selected tab is in sync with URL/path was when the application was loaded.
    return findTabFromPath(location);
  });

  useEffect(() => {
    return history.listen(location => {
      selectTab(findTabFromPath(location));
    });
  }, []);

  const [open, setOpen] = useState(false);

  function handleDrawerTabSelect(tab_index) {
    selectTab(tab_index);
    setOpen(false);
  }

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  function handleThemeChange(state) {
    localStorage.setItem(themeKey, state);
    changeTheme(state);
  }

  // let SelectedTab = tabs[currentTab].props.component;
  return (
    <div>
      <AppBar position="static" className={classNames(classes.appBar)}>
        <Toolbar disableGutters={true}>
          <Tooltip title={`Main Menu`}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={handleDrawerOpen}
              className={classNames(classes.menuButton)}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
          <Tabs
            style={{ flexGrow: 1 }} // to take up entire tab bar / push buttons right
            value={currentTab}
            onChange={(_event, value) => handleDrawerTabSelect(value)}
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs.map((tab, i) => (
              <Tab
                className={classNames(classes.thinFont)}
                component={RRLink}
                to={tab.props.path}
                key={tab.props.label}
                label={tab.props.label}
              />
            ))}
          </Tabs>
          {currentUser.authenticated ? (
            <>
              <Tooltip title={`Edit Your Profile`}>
                <IconButton
                  component={RRLink}
                  to={`/profile/edit/${currentUser.id}`}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </Tooltip>
              <Tooltip title={`Logged On As: ${currentUser.username}`}>
                {atLeastSmall ? (
                  <Button
                    component={RRLink}
                    to={'/logout'}
                    color="inherit"
                    className={classNames(classes.logButton, classes.thinFont)}
                  >
                    Logout
                  </Button>
                ) : (
                  <IconButton component={RRLink} to={'/logout'} color="inherit">
                    <ExitToApp />
                  </IconButton>
                )}
              </Tooltip>
            </>
          ) : (
            <Tooltip title={`Click to Log In or Sign Up`}>
              <Button
                component={RRLink}
                to={'/login'}
                color="inherit"
                className={classNames(classes.logButton, classes.thinFont)}
              >
                Login
              </Button>
            </Tooltip>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classNames(classes.drawerHeader)}>
          <Typography align="center" variant="h6" style={{ flexGrow: 1 }}>
            Main Menu
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {tabs.map((tab, index) => (
            <ListItem
              button
              component={RRLink}
              to={tab.props.path}
              key={tab.props.label}
              onClick={() => handleDrawerTabSelect(index)}
            >
              <ListItemIcon>
                <Icon>fiber_manual_record</Icon>
              </ListItemIcon>
              <ListItemText primary={tab.props.label} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          <ListItem>
            <Switch
              value={themeSwitchEngaged}
              checked={themeSwitchEngaged}
              onChange={event => handleThemeChange(event.target.checked)}
            />
            <ListItemText primary={`Change Theme`} />
          </ListItem>
        </List>
      </Drawer>
      <RRSwitch>{children}</RRSwitch>
    </div>
  );
}

TabChooser.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(withTheme()(TabChooser)));
