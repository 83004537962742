import React from 'react';
import { Redirect, withRouter } from 'react-router';

export default function RequireAdmin(Component) {
  const wrapper = props => {
    let { history, currentUser } = props;
    console.log(`User must be an admin: ${currentUser.admin}`);
    if (currentUser && currentUser.authenticated && currentUser.admin) {
      return <Component {...props} />;
    } else {
      return (
        <Redirect
          to={{
            pathname: `/`,
            state: {
              from: history.location
            }
          }}
        />
      );
    }
  };

  return withRouter(wrapper);
}
