import React, { useState } from 'react';
import PropTypes from 'prop-types';

// The Form
import RegisterForm from '../mui-forms/registration-form';

import { tokenKey, currentUserKey } from '../../credentials';

import RequireAuthentication from '../../require-authenticate';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { makeAPICall } from '../../api';
import my_styles from '../../my-styles';
const styles = my_styles.MUIApp_styles;

let EditProfile = ({ classes, currentUser, updateUser, enqueueSnackbar }) => {
  let [message, setMessage] = useState('');
  let [inProgress, setInProgress] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);
  let [fetchingData, setFetchingData] = useState(true);
  let [userFields, setUserFields] = useState({});

  let editProfile = async values => {
    setMessage('');
    setInProgress(true);
    try {
      let res = await makeAPICall(
        'PUT',
        `/api/users/${currentUser.id}`,
        values
      );
      let body = await res.json();
      console.log(body);
      setInProgress(false);
      if (body.message === 'user updated') setIsSuccess(true);
      if (body.message !== 'user updated') setMessage(body.message);
      if (res.status === 200) {
        localStorage.setItem(tokenKey, body.token);
        localStorage.setItem(currentUserKey, JSON.stringify(body.user));
        updateUser({
          ...body.user,
          authenticated: true,
          admin: body.user.admin === 1
        });
        enqueueSnackbar(`You updated your profile!`, {
          variant: 'success',
          autoHideDuration: 5000
        });
      }
    } catch (err) {
      setInProgress(false);
      setMessage('Unable to connect to server.');
    }
  };

  let fetchData = async () => {
    try {
      let res = await makeAPICall('GET', `/api/users/${currentUser.id}`);
      let body = await res.json();
      console.log(body);
      setMessage(body.message);
      if (res.status === 200) {
        const { username, firstname, lastname, email } = body;
        setUserFields({ username, firstname, lastname, email });
        setFetchingData(false);
      }
    } catch (err) {
      setFetchingData(false);
      setMessage('Unable to connect to server.');
    }
  };

  if (fetchingData) {
    console.log('fetching user data');
    fetchData();
  }

  return (
    <div className={classes.root}>
      <Typography align="center" variant="h5" gutterBottom>
        Edit Your Profile
      </Typography>
      {fetchingData ? (
        <LinearProgress />
      ) : (
        <>
          <RegisterForm
            fetchingData={fetchingData}
            onSubmit={editProfile}
            message={message}
            inProgress={inProgress}
            isSuccess={isSuccess}
            username={userFields.username}
            firstname={userFields.firstname}
            lastname={userFields.lastname}
            email={userFields.email}
            update_password={true}
          />
          <Typography align="center" variant="body1" gutterBottom>
            You can change your first name, last name, email, or password.
          </Typography>
        </>
      )}
    </div>
  );
};

EditProfile.propTypes = {
  currentUser: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired
};

export default withStyles(styles)(RequireAuthentication(EditProfile));
