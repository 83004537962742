import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

import my_styles from '../../my-styles';
import RequireAuthentication from '../../require-authenticate';
const styles = my_styles.MUIApp_styles;

let UserProfile = ({ classes, currentUser, theme }) => {
  const atLeastSmall = useMediaQuery(theme.breakpoints.up('sm'));

  if (!currentUser) {
    return (
      <div className={classes.root}>
        <Typography align="center" variant="h5" gutterBottom>
          Login or Register to view your profile information.
        </Typography>
      </div>
    );
  }

  const insides = (
    <Paper>
      <Table className={classes.root}>
        <TableBody>
          <TableRow>
            <TableCell className={classes.bold}>User ID:</TableCell>
            <TableCell>{currentUser.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.bold}>Username:</TableCell>
            <TableCell>{currentUser.username}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.bold}>First Name:</TableCell>
            <TableCell>{currentUser.firstname}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.bold}>Last Name:</TableCell>
            <TableCell>{currentUser.lastname}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.bold}>Email:</TableCell>
            <TableCell>{currentUser.email}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );

  return (
    <div className={classes.root}>
      <Typography align="center" variant="h5" gutterBottom>
        Current User Profile
      </Typography>
      <div style={{ textAlign: 'center' }}>
        {atLeastSmall ? (
          <div style={{ width: '75%', display: 'inline-block' }}>{insides}</div>
        ) : (
          <div>{insides}</div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(
  RequireAuthentication(withTheme()(UserProfile))
);
