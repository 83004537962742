import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Forms
import VotingForm from '../mui-forms/voting-form';
import VotingResults from '../mui-forms/voting-results-display';
import RequireAuthentication from '../../require-authenticate';

import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeAPICall } from '../../api';
import my_styles from '../../my-styles';
const styles = my_styles.MUIApp_styles;

// ---------------------------------------------------------------------------

let VoteQuestion = ({
  classes,
  currentUser,
  updateUser,
  match,
  location,
  enqueueSnackbar
}) => {
  const [answersMessage, updateAnswersMessage] = useState(null);
  const [answerList, updateAnswerList] = useState([]);
  const [answersInProgress, setAnswersInProgress] = useState(false);
  const [prevAnswerInProgress, setPrevAnswerInProgress] = useState(false);
  const [prevAnswer, setPrevAnswer] = useState(-1);
  const [submissionInProgress, setSubmissionInProgress] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [shouldGetResults, setShouldGetResults] = useState(false);
  const [voteTotals, setVoteTotals] = useState([]);
  const [isRetrieved, setRetrieved] = useState(false);
  const [question, setQuestion] = useState('');
  const [description, setDescription] = useState('');

  const qid = match && match.params && match.params.qid;

  // SUBMIT ANSWER =====================================================
  let onSubmit = async value => {
    // console.log(value);
    setSubmissionInProgress(true);

    let res;
    try {
      res = await makeAPICall('POST', `/api/question/${qid}/vote`, {
        choice: value
      });
      let body = await res.json();
      // console.log(body);
      // updateAnswersMessage(body.message);
      setSubmissionInProgress(false);
      setSubmissionSuccess(body.message === 'successfully voted');
      setShouldGetResults(body.message === 'successfully voted');
      if (body.message === 'successfully voted') {
        enqueueSnackbar(`Yay! You voted!`, {
          variant: 'success',
          autoHideDuration: 3000
        });
      }
    } catch {
      setSubmissionInProgress(false);
      if (res.status === 500)
        updateAnswersMessage('Unable to connect to server');
    }
  };

  // GET ANSWER CHOICES =================================================
  let getAnswerList = async () => {
    updateAnswersMessage(null);
    setAnswersInProgress(true);

    let res;
    try {
      res = await makeAPICall('GET', `/api/question/${qid}`);
      let body = await res.json();
      // console.log(body);
      updateAnswersMessage(body.message);
      updateAnswerList(body.choices);
      setQuestion(body.question);
      setDescription(body.description);
      setAnswersInProgress(false);
    } catch {
      setAnswersInProgress(false);
      if (res.status === 500)
        updateAnswersMessage('Unable to connect to server');
    }
  };

  // GET USER'S PREVIOUS VOTE ===========================================
  let getPreviousVote = async () => {
    setPrevAnswerInProgress(true);

    let res;
    try {
      res = await makeAPICall(
        'GET',
        `/api/question/${qid}/vote?user=${currentUser.id}`
      );
      let body = await res.json();
      // console.log(body.votes);
      if (body.votes.length !== 0) {
        setPrevAnswer(body.votes[0]);
        setShouldGetResults(true);
        enqueueSnackbar(`You already voted on this question.`, {
          variant: 'info',
          autoHideDuration: 4000
        });
      }
      setPrevAnswerInProgress(false);
    } catch {
      setPrevAnswerInProgress(false);
      if (res.status === 500)
        updateAnswersMessage('Unable to connect to server');
    }
  };

  // GET VOTING RESULTS =================================================
  let getVotingResults = async () => {
    let res;
    try {
      res = await makeAPICall('GET', `/api/question/${qid}/vote`);
      let body = await res.json();
      console.log(body);
      setVoteTotals(body.totals);
      setShouldGetResults(false);
    } catch {
      setShouldGetResults(false);
      if (res.status === 500)
        updateAnswersMessage('Unable to connect to server');
    }
  };

  // ACTUAL RETRIEVE LOGIC ================================================
  if (!isRetrieved) {
    getAnswerList();
    getPreviousVote();
    setRetrieved(true);
  }

  if (shouldGetResults) {
    console.log('Fetching survey results.');
    getVotingResults();
  }

  // UPDATE VOTES =========================================================
  let updateVotes = async () => {
    let res;
    try {
      res = await makeAPICall('GET', `/api/question/${qid}/vote`);
      let body = await res.json();
      console.log(body);
      setVoteTotals(body.totals);
    } catch {}
  };

  // USE EFFECT FOR VOTE UPDATES =========================================

  return (
    <div className={classes.root}>
      <Typography align="center" variant="h5" gutterBottom>
        Question #{qid}
      </Typography>
      {answersInProgress && <LinearProgress />}
      {answersMessage ? (
        <div>
          <Typography align="center" variant="body1" color="error" gutterBottom>
            {answersMessage}
          </Typography>
        </div>
      ) : null}

      {!answersInProgress && !prevAnswerInProgress && (
        <>
          <VotingForm
            question={question}
            description={description}
            answerList={answerList}
            onSubmit={onSubmit}
            previousVote={prevAnswer}
            isSubmitting={submissionInProgress}
            submitSuccess={submissionSuccess || prevAnswer !== -1}
          />
          <VotingResults
            answerList={answerList}
            userVoted={submissionSuccess || prevAnswer !== -1}
            voteTotals={voteTotals}
            updateVotes={updateVotes}
            questionID={qid}
          />
          {/* {(submissionInProgress || shouldGetResults) && <LinearProgress />} */}
        </>
      )}
    </div>
  );
};

VoteQuestion.propTypes = {
  currentUser: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired
};

export default withStyles(styles)(RequireAuthentication(VoteQuestion));
